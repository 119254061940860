<template>
<div>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="posturl"
      :title="title"
      :data-detail="details"
    />
  </b-card>
  <b-card title="Penanggung Jawab TTD">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols-md="4"
              label="Tipe Section"
            >
              <validation-provider
                #default="{ errors }"
                name="Section"
                rules="required"
              >
                <b-form-select
                  v-model="formDetails.section"
                  :options="opSection"
                  value-field="key"
                  text-field="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              label="Nama"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama"
                rules="required"
              >
                <b-form-input
                  v-model="formDetails.name"
                  :plaintext="false"
                  placeholder="Inputkan Nama"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              label="Jabatan"
            >
              <validation-provider
                #default="{ errors }"
                name="Jabatan"
                rules="required"
              >
                <b-form-input
                  v-model="formDetails.title"
                  :plaintext="false"
                  placeholder="Inputkan Jabatan"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <form-file
              v-model="formDetails.file"
              rules="required"
              label="Upload TTD"
              placeholder="Upload Image TTD"
            /> -->
            <b-form-group
              label-cols-md="4"
              label="File TTD"
            >
              <validation-provider
                #default="{ errors }"
                name="File TTD"
                rules="required"
              >
                <!-- <b-form-select
                  v-model="formDetails.file_ttd"
                  :options="opFileTTD"
                  value-field="key"
                  text-field="name"
                /> -->
                <v-select
                  v-model="ttdFile"
                  multiple
                  label="name"
                  :options="opFileTTD"
                  placeholder="Select File TTD"
                  @input="changeFileTTD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="4"
              label=""
            >
              <b-button
                class="mr-1"
                variant="warning"
                size="sm"
                type="button"
                @click.prevent="reset"
              >
                <span>Reset</span>
              </b-button>
              <b-button
                class="mr-1"
                variant="primary"
                size="sm"
                type="submit"
                :disabled="loading"
                @click.prevent="validationForm"
              >
              
                <span>{{loading?'Loading...':'Tambah'}}</span>
              </b-button>
            </b-form-group>
          </b-col>
          <br>
        </b-row>
      </b-form>
    </validation-observer>
    <br>
    <b-table
      v-if="renderComp"
      striped
      responsive
      :items="details"
      :fields="table_details"
    >
      <template #cell(_)="data">
        <span
          class="mr-1"
        >
          <b-link @click="confirmDelete(data)">
            <b-badge variant="danger">
              <feather-icon icon="TrashIcon" />
            </b-badge>
          </b-link>
        </span>
      </template>
      <template #cell(section)="data">
        {{data.item.section == 'direction'?'Direksi':'Keuangan'}}
      </template>
      <template #cell(file_ttd)="data">
        {{ getTextFile(data.item.file_ttd) }}
      </template>
    </b-table>
  </b-card>
</div>
  
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton, BFormCheckbox} from 'bootstrap-vue'
import FormFile from '@/views/base/form/FormFile.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BCard,
    BForm, 
    BRow, 
    BCol, 
    BTable, 
    BLink, 
    BBadge, 
    BFormGroup, 
    BFormSelect, 
    BFormInput, 
    BButton,
    FormFile,
    BFormCheckbox,
    vSelect
  },
  data() {
    return {
      title:'Vendor',
      posturl:'/vendor',
      fields :[
        { key:'photo', label:'Logo Vendor', placeholder:'Logo Vendor', type: 'image', rules:'' },
        { key:'region', label:'Akses Region', type: 'select2',placeholder:'Select Akses Region', rules:'required',dataurl:'/region'},
        { key:'code', label:'Kode', placeholder:'Kode Vendor', type: 'input',rules:'required' },
        { key:'name', label:'Nama', placeholder:'Nama Vendor', type: 'input',rules:'required' },
        { key:'address', label:'Alamat', placeholder:'Alamat',  type: 'autocomplate',rules:'required' },
        { type:'map', label:''},
        { key:'city', label:'Kota', placeholder:'Masukkan Kota', type: 'autocomplate', indexValue:'name', objectView:'data', dataurl:'/city?search=' },
        { key:'postal_code', label:'Kode pos', placeholder:'Kode Pos',  type: 'input',rules:'' },
        { key:'email', label:'Email', placeholder:'Email',  type: 'input',rules:'required|email' },
        // { key:'vendor_type', label:'Tipe', type: 'select2',rules:'',dataurl:'/vendor-type' },
        { key:'phone', label:'Telephone', placeholder:'Telephone',  type: 'input',rules:'' },
        { key:'hr'},
        { key:'cp_name', label:'Contact Person', placeholder:'Nama Contact Person', type: 'input',rules:'required' },
        { key:'cp_number', label:'Telephone Contact Person', placeholder:'Telephone', type: 'input',rules:'' },
        { key:'cp_email', label:'Email Contact Person', placeholder:'Email', type: 'input',rules:'' },
        { key:'hr'},
        { key:'date', label:'Tanggal Register',  type: 'date',rules:'', val:'now' },
        { key:'hr'},
        { key:'section', desc: 'Data Bank'},
        { key:'account_bank', label:'Nama Bank', placeholder:'Nama Bank', type: 'input',rules:'required' },
        { key:'account_owner', label:'Nama Penerima', placeholder:'Nama Penerima', type: 'input',rules:'required' },
        { key:'account_number', label:'No Rekening', placeholder:'No Rekening', type: 'input',rules:'required' },
        // { key:'hr'},
        // { key:'section', desc: 'Section Direksi'},
        // { key:'director_name', label:'Nama', placeholder:'Nama Direksi', type: 'input',rules:'required' },
        // { key:'director_jobtitle', label:'Jabatan', placeholder:'Jabatan Direksi', type: 'input',rules:'required' },
        // { key:'hr'},
        // { key:'section', desc: 'Section Keuangan'},
        // { key:'accountant_name', label:'Nama', placeholder:'Nama Tertuju', type: 'input',rules:'required' },
        // { key:'accountant_jobtitle', label:'Jabatan', placeholder:'Jabatan Tertuju', type: 'input',rules:'required' },
        { key: 'details', type: 'details'}
      ],
      table_details:[
        { key: 'section', label: 'Penanggung Jawab TTD'},
        { key: 'name', label: 'Nama'},
        { key: 'title', label: 'Jabatan' },
        { key: 'file_ttd', label: 'File TTD'},
        // { key: 'file', label: 'File' },
        '_'
      ],
      details:[],
      formDetails:{
        section: "direction"
      },
      opSection:[
        {key: 'direction', name: 'Direksi'},
        {key: 'finance', name: 'Keuangan'},
      ],
      opFileTTD:[
        {key: 'bast', name: 'BAST'},
        {key: 'rikmatek', name: 'RIKMATEK'},
        {key: 'invoice', name: 'INVOICE'},
        {key: 'kwitansi', name: 'KWITANSI'},
        {key: 'spb', name: 'SPB'},
        {key: 'spp', name: 'SPP'},
        {key: 'contract', name: 'KONTRAK'},
        {key: 'badk', name: 'BA Denda'},
      ],
      fileTTD:{
        bast:'BAST',
        rikmatek:'RIKMATEK',
        invoice:'INVOICE',
        kwitansi:'KWITANSI',
        spb:'SPB',
        spp:'SPP',
        contract:'KONTRAK',
        badk:'BA Denda'
      },
      ttdFile:null,
      loading:false,
      renderComp:true
    }
  },
  created() {
    if(this.$route.params.id != null){
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        if(res.data.details)
          this.details = res.data.details
        if (res.data.user){
          let have_accounts_fields = [
              { key:'hr'},
              { type: 'p', title:'Akun Aplikasi Vendor Channel sudah terdaftar', value:'User name yang digunakan adalah email vendor yang dimasukkan' },
          ]
          for (let have_accounts_field of have_accounts_fields){
            this.fields.push(have_accounts_field);
          }
        }
      })
    }
    else{
      let password_fields = [
        { key:'hr'},
        { type: 'p', title:'Akun Aplikasi Vendor Channel', value:'User name yang digunakan adalah email yang dimasukkan' },
        { key:'create_user', label: '', type: 'bool', title:'Daftarkan Akses aplikasi' },
        { key:'password', label:'Password', placeholder:'Input Password', type: 'password',rules:'' ,disabled:true },
        { key:'retype_password', label:'Ulang Password', placeholder:'Ulangi Password', type: 'password',rules:'',disabled:true },
      ]
      for (let password_field of password_fields){
        this.fields.push(password_field);
      }
    }
  },
  methods:{
    confirmDelete(data){
      this.details.splice(data.index, 1)
    },
    reset(){
      this.formDetails = {
        section : "direction"
      }
      this.ttdFile = null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then( res => {
        if(res){
          var is_return = false
          this.ttdFile.forEach(file => {
            if(this.details.find(detail => detail.file_ttd.includes(file.key) && detail.section == this.formDetails.section)){
              is_return = true
              return this.$bvToast.toast('File TTD '+(this.formDetails.section == 'direction'?'Direksi':'Keuangan')+" "+file.name+' already exists', {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
            }
              
          })

          if(!is_return){
            this.loading = true
            if(this.formDetails.file){
              let formData = new FormData()
              formData.append('file', this.formDetails.file)
              this.$http.post('upload/image', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(res => {
                this.loading = false
                this.formDetails.file = res.data.path
                this.details.push(this.formDetails)
                this.reset()
                this.$refs.simpleRules.reset()
              })
              .catch( err => {
                this.loading = false
                const msg = err.response.data.message
                return this.$bvToast.toast((msg)?msg:'File not found', {
                  title: 'Error',
                  solid: true,
                  variant:'danger'
                })
              })
            }else{
              this.loading = false
              this.details.push(this.formDetails)
              this.reset()
              this.$refs.simpleRules.reset()
            }
              
          }
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    changeFileTTD(data){
      var stringFile = ""
      data.forEach((file, key) => {
        if(key>0 && key<data.length)
          stringFile += " | "
        
        stringFile += file.key
      })
      this.formDetails.file_ttd = stringFile
    },
    getTextFile(file){
      var stringFile = ""
      if(file.includes(" | ")){
        file.split(" | ").forEach((val, key) => {
          if(key>0 && key<file.split(" | ").length)
            stringFile += " | "
        
          stringFile += this.fileTTD[val]
        })
      }else{
        stringFile = this.fileTTD[file]
      }
      return stringFile
    }
    // actionCheck(result){
    //   var data = result.item
    //   var index = result.index
    //   var result = this.resetCheck(data.section)
    //   this.details = result
    //   this.details[index].is_ttd = data.is_ttd
    //   this.renderComp = false
    //   this.$nextTick().then(() => {
    //     this.renderComp = true
    //   })
    // },
    // resetCheck(section){
    //   this.details = this.details.map(value => {
    //     return{
    //       ...value,
    //       is_ttd:value.section == section?false:value.is_ttd?true:false
    //     }
    //   })
    //   return this.details
    // }
  }
}
</script>