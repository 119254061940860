var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('baseform',{attrs:{"fields":_vm.fields,"posturl":_vm.posturl,"title":_vm.title,"data-detail":_vm.details}})],1),_c('b-card',{attrs:{"title":"Penanggung Jawab TTD"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Tipe Section"}},[_c('validation-provider',{attrs:{"name":"Section","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.opSection,"value-field":"key","text-field":"name"},model:{value:(_vm.formDetails.section),callback:function ($$v) {_vm.$set(_vm.formDetails, "section", $$v)},expression:"formDetails.section"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Nama"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"plaintext":false,"placeholder":"Inputkan Nama","state":errors.length > 0 ? false:null},model:{value:(_vm.formDetails.name),callback:function ($$v) {_vm.$set(_vm.formDetails, "name", $$v)},expression:"formDetails.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Jabatan"}},[_c('validation-provider',{attrs:{"name":"Jabatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"plaintext":false,"placeholder":"Inputkan Jabatan","state":errors.length > 0 ? false:null},model:{value:(_vm.formDetails.title),callback:function ($$v) {_vm.$set(_vm.formDetails, "title", $$v)},expression:"formDetails.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"4","label":"File TTD"}},[_c('validation-provider',{attrs:{"name":"File TTD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","label":"name","options":_vm.opFileTTD,"placeholder":"Select File TTD"},on:{"input":_vm.changeFileTTD},model:{value:(_vm.ttdFile),callback:function ($$v) {_vm.ttdFile=$$v},expression:"ttdFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":""}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","size":"sm","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('span',[_vm._v("Reset")])]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm","type":"submit","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.loading?'Loading...':'Tambah'))])])],1)],1),_c('br')],1)],1)],1),_c('br'),(_vm.renderComp)?_c('b-table',{attrs:{"striped":"","responsive":"","items":_vm.details,"fields":_vm.table_details},scopedSlots:_vm._u([{key:"cell(_)",fn:function(data){return [_c('span',{staticClass:"mr-1"},[_c('b-link',{on:{"click":function($event){return _vm.confirmDelete(data)}}},[_c('b-badge',{attrs:{"variant":"danger"}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)]}},{key:"cell(section)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.section == 'direction'?'Direksi':'Keuangan')+" ")]}},{key:"cell(file_ttd)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getTextFile(data.item.file_ttd))+" ")]}}],null,false,4068837872)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }